<template>
  <div>
    <div class="row p-0">
      <div class="col-md-8 col-sm-12">
        <div class="d-flex justify-content-start flex-wrap">
          <div
            class="dashboardCards-wrapper"
            v-for="card in cards"
            :key="card.id + '_' + Math.random()"
          >
            <div class="analytics-card">
              <div class="">
                <p class="text-grey mb-0">{{ card.title }}</p>
                <h3 class="mb-0 text-black font-weight-bold">
                  {{ card.count }}
                </h3>
              </div>
              <div>
                <!--            <h3 class="mb-0 text-black font-weight-bold">{{ card.count }}</h3>-->
                <!--          <donut :seriesArray="card.series" />-->
                <img :src="card.icon" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mb-4">
        <div class="shadow-box">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4>Total Revenue</h4>
            </div>
            <div>
              <h4>£{{ total_revenue ? total_revenue.total_revenue : 0 }}</h4>
            </div>
          </div>
          <div class="mt-3">
            <img :src="'graph-img.png'" alt="graph" class="w-100" />
          </div>
        </div>
      </div>
    </div>
    <div class="sales-stats-wrapper">
      <div class="sales-stats-first">
        <div class="sales-restaurants-revenue">
          <div class="sales-stats-inner-first">
            <div class="shadow-box">
              <div class="sales-statistics-wrapper">
                <div class="sales-statistics-heading">
                  <h4>{{ statistics }}</h4>
                </div>
                <div
                  class="d-flex align-items-center justify-content-end selectBox-wrapper"
                >
                  <div class="form-group mb-0 position-relative w-100">
                    <!--                <i class="fas fa-angle-down text-orange"></i>-->
                    <!--                <select class="select-input w-100" id="sel11">-->
                    <!--                  <option>Weekly</option>-->
                    <!--                  <option>2</option>-->
                    <!--                  <option>3</option>-->
                    <!--                  <option>4</option>-->
                    <!--                </select>-->
                  </div>
                  <!--              <a href=""><img :src="'vertIcon.png'"/></a>-->
                </div>
              </div>
              <div class="beverages-wrapper">
                <!--            <div-->
                <!--              class="sales-statistics-heading d-flex justify-content-between"-->
                <!--            >-->
                <!--              <ul class="m-0 p-0">-->
                <!--                <li class="dot-icon pr-4">-->
                <!--                  <p class="mb-0 pl-4">Order Received</p>-->
                <!--                </li>-->
                <!--                <li><h3 class="mb-0 font-weight-bold">346</h3></li>-->
                <!--              </ul>-->
                <!--              <ul class="m-0 p-0">-->
                <!--                <li class="dot-icon food pr-4">-->
                <!--                  <p class="mb-0 pl-4">Order Processed</p>-->
                <!--                </li>-->
                <!--                <li><h3 class="mb-0 font-weight-bold">52</h3></li>-->
                <!--              </ul>-->
                <!--            </div>-->
                <div
                  class="selectBox-wrapper d-flex align-items-center justify-content-between"
                >
                  <!--              <p class="mb-0">Number</p>-->
                  <!--              <div class="onoffswitch">-->
                  <!--                <input-->
                  <!--                  type="checkbox"-->
                  <!--                  name="onoffswitch"-->
                  <!--                  class="onoffswitch-checkbox"-->
                  <!--                  id="myonoffswitch"-->
                  <!--                  checked-->
                  <!--                />-->
                  <!--                <label class="onoffswitch-label mb-0" for="myonoffswitch">-->
                  <!--                  <span class="onoffswitch-inner"></span>-->
                  <!--                  <span class="onoffswitch-switch"></span>-->
                  <!--                </label>-->
                  <!--              </div>-->
                  <div class="form-group mb-0 position-relative w-100"></div>
                </div>
              </div>
              <div class="beverages-wrapper">
                <div
                  class="selectBox-wrapper d-flex align-items-center justify-content-between"
                ></div>
              </div>
              <div id="chart">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptions"
                  :series="line"
                ></apexchart>
              </div>
            </div>
          </div>
          <div class="sales-stats-inner-second">
            <div class="shadow-box">
              <div class="sales-statistics-wrapper">
                <div class="sales-statistics-heading">
                  <h4>{{ revenue }}</h4>
                </div>
                <div
                  class="d-flex align-items-center justify-content-end selectBox-wrapper"
                >
                  <div class="form-group mb-0 position-relative w-100">
                    <!--                <i class="fas fa-angle-down text-orange"></i>-->
                    <!--                <select class="select-input w-100" id="sel11">-->
                    <!--                  <option>Weekly</option>-->
                    <!--                  <option>2</option>-->
                    <!--                  <option>3</option>-->
                    <!--                  <option>4</option>-->
                    <!--                </select>-->
                  </div>
                  <!--              <a href=""><img :src="'vertIcon.png'"/></a>-->
                </div>
              </div>
              <div class="beverages-wrapper">
                <!--            <div-->
                <!--              class="sales-statistics-heading d-flex justify-content-between"-->
                <!--            >-->
                <!--              <ul class="m-0 p-0">-->
                <!--                <li class="dot-icon pr-4">-->
                <!--                  <p class="mb-0 pl-4">Order Received</p>-->
                <!--                </li>-->
                <!--                <li><h3 class="mb-0 font-weight-bold">346</h3></li>-->
                <!--              </ul>-->
                <!--              <ul class="m-0 p-0">-->
                <!--                <li class="dot-icon food pr-4">-->
                <!--                  <p class="mb-0 pl-4">Order Processed</p>-->
                <!--                </li>-->
                <!--                <li><h3 class="mb-0 font-weight-bold">52</h3></li>-->
                <!--              </ul>-->
                <!--            </div>-->
                <div
                  class="selectBox-wrapper d-flex align-items-center justify-content-between"
                >
                  <!--              <p class="mb-0">Number</p>-->
                  <!--              <div class="onoffswitch">-->
                  <!--                <input-->
                  <!--                  type="checkbox"-->
                  <!--                  name="onoffswitch"-->
                  <!--                  class="onoffswitch-checkbox"-->
                  <!--                  id="myonoffswitch"-->
                  <!--                  checked-->
                  <!--                />-->
                  <!--                <label class="onoffswitch-label mb-0" for="myonoffswitch">-->
                  <!--                  <span class="onoffswitch-inner"></span>-->
                  <!--                  <span class="onoffswitch-switch"></span>-->
                  <!--                </label>-->
                  <!--              </div>-->
                  <div class="form-group mb-0 position-relative w-100"></div>
                </div>
              </div>
              <div class="beverages-wrapper">
                <div
                  class="selectBox-wrapper d-flex align-items-center justify-content-between"
                ></div>
              </div>
              <div id="chart-revenue">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptions"
                  :series="revenue_line"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="sales-restaurants-revenue">
          <div class="sales-stats-inner-first">
            <div class="shadow-box mt-4">
              <div class="sales-statistics-wrapper">
                <div class="sales-statistics-heading">
                  <h4>Sales statistics</h4>
                </div>
                <div
                  class="d-flex align-items-center justify-content-end selectBox-wrapper"
                >
                  <!--                  <div class="form-group mb-0 position-relative w-100">-->
                  <!--                    <i class="fas fa-angle-down text-orange"></i>-->
                  <!--                    <select class="select-input w-100" id="sel21">-->
                  <!--                      <option>Monthly</option>-->
                  <!--                      <option>2</option>-->
                  <!--                      <option>3</option>-->
                  <!--                      <option>4</option>-->
                  <!--                    </select>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="sales-summary-wrapper mt-4">
                <!--            <radialBar />-->
                <img
                  :src="'ringProgressbar.png'"
                  alt="ringprocess"
                  class="sales-summary-chart"
                />
                <ul
                  class="d-flex justify-content-between align-items-start flex-wrap sales-summary-revenue"
                >
                  <li class="menu-sold">
                    <div class="sales-li-inner">
                      <p>{{ sales.menus_sold }}</p>
                      <span>Menu Sold</span>
                    </div>
                  </li>
                  <li class="">
                    <!--                    <div class="sales-li-inner">-->
                    <!--                      <p>63,876</p>-->
                    <!--                      <span>Safe(20%)</span>-->
                    <!--                    </div>-->
                  </li>
                  <li class="revenue pt-4">
                    <div class="sales-li-inner">
                      <p>£{{ sales.total_sale_amount }}</p>
                      <span>Revenue</span>
                    </div>
                  </li>
                  <li class="">
                    <!--                    <div class="sales-li-inner">-->
                    <!--                      <p>63,876</p>-->
                    <!--                      <span>Revenue</span>-->
                    <!--                    </div>-->
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sales-stats-inner-second">
            <div class="shadow-box">
              <h4>Loyal Customers</h4>
              <p class="text-grey"></p>
              <div class="section-scroll">
                <div
                  class="d-flex justify-content-start mb-4"
                  v-for="customer in customers"
                  :key="customer.user_id + '_' + Math.random()"
                >
                  <img
                    :src="'icontest.png'"
                    alt="loyal-img"
                    width="45"
                    height="45"
                  />
                  <!--                        <img :src="customer.user.avatar_file.path" alt="loyal-img" />-->
                  <div class="loyal-customers-wrapper best-seller-menus ml-3">
                    <h5>{{ customer.user ? customer.user.name : " " }}</h5>
                    <p class="text-orange">{{ customer.orders }} Times Order</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="favourite-items-first mt-4">
          <div class="shadow-box pl-0 pr-0">
            <div class="sales-statistics-wrapper pr-3 pl-3">
              <div class="sales-statistics-heading">
                <h4>Most Favourite Items</h4>
                <p class="text-grey"></p>
              </div>
            </div>
            <div
              class="mt-4 favourite-items-detail border-bottom px-3"
              v-for="fav in favItems"
              :key="fav.menu_item_id + '_' + Math.random()"
            >
              <div class="favouite-item-detail-first">
                <div>
                  <img
                    v-if="checkObjectIsExist(fav.menu_item)"
                    :src="checkObjectIsAvatarSrc(fav.menu_item)"
                    alt=""
                    width="100"
                    height="75"
                    class="favourite-item-detail-img mr-3"
                    @error="imageLoaderError"
                  />
                  <img
                    v-else
                    :src="'favitem.jpg'"
                    alt="favourite-items"
                    width="100"
                    height="75"
                    class="favourite-item-detail-img mr-3"
                  />
                </div>
                <div class="best-seller-menus">
                  <h5>{{ fav.menu_item.item_name }}</h5>
                  <p>
                    <i
                      v-for="n in 5"
                      :key="Math.random() + '-' + n"
                      :class="
                        n <= fav.fav_item
                          ? 'fas fa-star text-yellow'
                          : 'fas fa-star'
                      "
                    ></i>
                    <!--                    <i class="fas fa-star text-yellow"></i>-->
                    <!--                    <i class="fas fa-star text-yellow"></i>-->
                    <!--                    <i class="fas fa-star"></i>-->
                    <!--                    <i class="fas fa-star"></i>-->
                  </p>
                  <p>
                    <i class="fas fa-heart mr-3 text-orange"></i>
                    <span class="text-orange likes"
                      >{{ fav.fav_item }} Like it</span
                    >
                  </p>
                </div>
              </div>
              <div class="favourite-items-second">
                <div>
                  <img
                    :src="'graph.png'"
                    alt="graph"
                    class="graph-chart-section"
                  />
                </div>
                <!--                <div>-->
                <!--                  <p class="mb-0 font-weight-bold">45%</p>-->
                <!--                  <span>Interest</span>-->
                <!--                </div>-->
                <div>
                  <div
                    class="d-flex justify-content-start align-items-center stats-img-detail"
                  >
                    <img :src="'ic_stat.png'" alt="stats" />
                    <p class="mb-0 font-weight-bold ml-2">
                      {{ fav.menu_item.total_paid_amount_item }}
                    </p>
                  </div>
                  <span>Total Sales</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="best-seller-menus sales-stats-second mt-4 mt-xl-0">
        <div class="shadow-box">
          <h4>Best Seller Menus</h4>
          <div
            class="section-scroll"
            v-for="menu in best_sell_menus"
            :key="menu.group.uuid + '_' + Math.random()"
          >
            <div class="SectionafterBorder">
              <img :src="menu.group.src" alt="best seller image" class="w-25" />
              <h5 class="mt-3 mb-3">{{ menu.group.group_name }}</h5>
            </div>
          </div>
        </div>
        <div class="shadow-box mt-4 px-0">
          <h4 class="px-3">Daily Trending Menus</h4>
          <p class="text-grey px-3"></p>
          <div class="section-scroll">
            <div
              class="border-bottom daily-trending-wrapper mb-4 px-3"
              v-for="item in usedItems"
              :key="item.id + '_' + Math.random()"
            >
              <div class="d-flex justify-content-start">
                <img :src="'menu.png'" alt="loyal-img" />
                <div
                  class="loyal-customers-wrapper best-seller-menus trending-menu-list ml-3"
                >
                  <h5>{{ item.item_name }}</h5>
                  <p></p>
                  <div class="d-flex justify-content-between">
                    <p class="mb-0">
                      Order <b>{{ item.menu_items_count }}x</b>
                    </p>
                    <p class="mb-0 font-weight-bold">£ {{ item.item_price }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-box mt-4">
          <h4>Customers</h4>
          <p class="text-grey"></p>
          <div class="section-scroll">
            <div
              class="d-flex justify-content-start mb-4"
              v-for="customer in all_customers"
              :key="customer.user_id + '_' + Math.random()"
            >
              <img
                :src="'icontest.png'"
                alt="loyal-img"
                width="45"
                height="45"
              />
              <!--                        <img :src="customer.user.avatar_file.path" alt="loyal-img" />-->
              <div class="loyal-customers-wrapper best-seller-menus ml-3">
                <h5>{{ customer.name }}</h5>
                <p class="text-orange mb-0">Email: {{ customer.email }}</p>
                <p class="text-orange">Contact: {{ customer.mobile_number }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <donut />-->
    <br />
    <br />
  </div>
</template>
<script>
// import donut from "./Donut.vue";
// import radialBar from "./RadialBar";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ApaxChart",
  props: {
    cards: {
      type: Array
    },
    line: {
      type: Array
    },
    revenue_line: {
      type: Array
    },
    customers: {
      type: Array
    },
    all_customers: {
      type: Array
    },
    usedItems: {
      type: Array
    },
    favItems: {
      type: Array
    },
    statistics: {
      type: String
    },
    revenue: {
      type: String
    },
    sales: {
      type: Object
    },
    best_sell_menus: {
      type: Array
    },
    total_revenue: {
      type: Object
    }
  },
  data() {
    return {
      series: [
        {
          name: "",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      singleSeries: [
        {
          name: "Total Users",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ]
        },
        yaxis: {
          title: {
            text: ""
          }
        },
        fill: {
          opacity: 1,
          colors: ["#FD683E", "#624FD1"]
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            }
          }
        }
      },
      singleChartOptions: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ]
        },
        yaxis: {
          title: {
            text: ""
          }
        },
        fill: {
          opacity: 1,
          colors: ["#FD683E", "#624FD1"]
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            }
          }
        }
      }
    };
  },
  components: {
    // donut,
    // radialBar,
    apexchart: VueApexCharts
  },
  methods: {
    checkObjectIsExist(item) {
      if (item) {
        return !!item.src;
      }
      return false;
    },
    checkObjectIsAvatarSrc(item) {
      if (item) {
        if (item.src) {
          return item.src;
        }
        return "";
      }
      return "";
    },
    imageLoaderError(event) {
      return (event.target.src = "favitem.jpg");
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
